import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import type { MissingConditionOption } from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import {
  buildPayloadFromConditionForm,
  type ConditionFormValues,
} from 'utils/professionalQualifications';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import useMissingConditionFormValues from 'components/credentials/conditionDialogs/useMissingConditionFormValues';
import type { ICondition, IQualification } from '@medely/web-components/types';
import { useTrackQualification } from 'hooks/useTrackQualification';

interface IMissingConditionFormProps {
  children: (props: {
    condition: MissingConditionOption;
    isActionable: boolean;
    submit: (values: { condition: ConditionFormValues }) => Promise<void>;
  }) => ReactNode;
  condition: ICondition;
  isSimpleCondition: boolean;
  missingOptions: MissingConditionOption[];
  onQualificationsResolve?: () => void;
  selectedOption: MissingConditionOption;
  setSelectedProfessionalQualificationId: (_: number) => void;
  setSelectedOption: (_: MissingConditionOption | undefined) => void;
}

type MissingConditionFormContext = {
  condition?: ICondition;
  options: MissingConditionOption[];
  selectedOption?: MissingConditionOption;
  selectedQualificationIndex: number;
  selectOption: (option?: MissingConditionOption, qualification?: IQualification) => void;
};

const MissingConditionFormContext = createContext<MissingConditionFormContext>({
  options: [],
  selectedQualificationIndex: 0,
  selectOption: () => null,
});

export const useMissingConditionForm = () => useContext(MissingConditionFormContext);

const MissingConditionForm = ({
  children,
  condition,
  isSimpleCondition,
  missingOptions,
  onQualificationsResolve,
  selectedOption,
  setSelectedProfessionalQualificationId,
  setSelectedOption,
}: IMissingConditionFormProps) => {
  const { submitProfessionalQualification } = useProfessionalQualifications();
  const [selectedQualificationIndex, setSelectedQualificationIndex] = useState<number>(0);
  const { values: conditionFormValues } = useMissingConditionFormValues(selectedOption);
  const { trackQualificationFinishEditing } = useTrackQualification();
  const selectedQualification = selectedOption?.qualifications?.[selectedQualificationIndex];
  const isActionable = isSimpleCondition || Boolean(selectedQualification);

  const submit = async (values: {
    condition: ConditionFormValues;
    qualification_automation_id?: number;
  }) => {
    const payload = buildPayloadFromConditionForm(
      values.condition,
      selectedQualificationIndex,
      true,
      values.qualification_automation_id,
    );

    await submitProfessionalQualification(payload);

    trackQualificationFinishEditing({
      qualificationId: selectedQualification.id,
      saved: true,
    });

    onQualificationsResolve?.();
  };

  const selectOption = (option?: MissingConditionOption, qualification?: IQualification) => {
    if (option) {
      const selectedProfessionalQualificationId = qualification
        ? option.qualificationToProfessionalQualification[qualification.id]?.id
        : option.qualificationToProfessionalQualification[option.qualifications[0].id]?.id;
      const currentIndex = Math.max(0, option.qualifications.indexOf(qualification));
      setSelectedQualificationIndex(currentIndex);
      setSelectedProfessionalQualificationId(selectedProfessionalQualificationId);
    }

    setSelectedOption(option);
  };

  useEffect(() => {
    if (!missingOptions.length) {
      onQualificationsResolve?.();
    } else if (isSimpleCondition) {
      selectOption(missingOptions[0]);
    }
  }, [missingOptions.length]);

  return (
    <MissingConditionFormContext.Provider
      value={{
        condition,
        options: missingOptions,
        selectedOption,
        selectOption,
        selectedQualificationIndex,
      }}
    >
      {children({ condition: conditionFormValues, submit, isActionable })}
    </MissingConditionFormContext.Provider>
  );
};

export default MissingConditionForm;
