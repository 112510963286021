import { gql } from 'graphql-request';

export const GET_POSITIONS_AND_SPECIALTIES = gql`
  query LocationPositionRateSettings($input: LocationPositionRateSettingQueryInput) {
    locationPositionRateSettings(input: $input) {
      id
      position {
        id
        display_name
        kind
        requires_specialty
      }
      specialty {
        id
        label
        name
      }
    }
  }
`;

export const GET_ALL_POSITIONS = gql`
  query AllPositions($input: PositionQueryInput) {
    positions(input: $input) {
      id
      display_name
      lead_description
      name
      qualifications_group_id
      recommended_positions {
        id
        display_name
        lead_description
        name
        qualifications_group_id
      }
      specialties {
        active
        id
        label
      }
    }
  }
`;

export const GET_ALL_POSITIONS_FOR_MARKETS = gql`
  query AllPositions($input: PositionQueryInput, $market_ids: [Int]!) {
    positions(input: $input) {
      id
      display_name
      lead_description
      name
      qualifications_group_id
      incrementality(market_ids: $market_ids)
      recommended_positions {
        id
        display_name
        lead_description
        name
        qualifications_group_id
      }
      specialties {
        active
        id
        label
      }
    }
  }
`;

export const GET_POSITION = gql`
  query Position($id: Int!) {
    position(id: $id) {
      display_name
      id
      lead_question
      lead_description
      recommended_positions {
        id
        lead_question
        lead_description
        requires_specialty
        specialties {
          id
          label
          active
        }
      }
      requires_specialty
      specialties {
        id
        label
        active
      }
    }
  }
`;

export const GET_PROFILE_POSITIONS_WITH_SPECIALTIES = gql`
  query ProfilePositionsWithSpecialties($input: PositionQueryInput) {
    positions(input: $input) {
      id
      display_name
      specialties {
        id
        label
      }
    }
  }
`;
