import { gql } from 'graphql-request';
import {
  DEFAULT_GRAPHQL_FRAGMENT,
  type GraphQLFragment,
  type IsAgencyAccountInput,
  isAgencyAccount,
} from '../utils';

export const getAvailableJobsQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceAvailableJobsFragment',
      fragment: gql`
        fragment MarketplaceAvailableJobsFragment on Job {
          great_pay
          guaranteed_minimum_hours
          job_billing_sums {
            payout_total_amount_cents
          }
          job_price_differentials {
            slug
            recommendation_used
          }
          job_profile_for_account_id {
            payout_facility_bonus_cents
          }
          status
          total_payout_bonus_cents
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query AvailablePerDiemJobs($input: JobQueryInput, $professional_id: Int!) {
      availableJobs(input: $input) {
        ${fragment?.name}
        can_book_job
        current_ends_time
        current_starts_time
        distance_in_miles(professional_id: $professional_id)
        id
        location {
          address_city
          address_zip
          company_id
          name
          state {
            abbreviation
          }
          timezone_lookup
        }
        location_id
        missing_condition_ids
        positions {
          display_name
        }
        requested_by_facility(professional_id: $professional_id)
        specialties {
          label
        }
        starts_date
      }
      _availableJobsMeta(input: $input) {
        count
      }
    }
  `;
};

export const GET_WEEK_JOBS = gql`
  query Jobs($input: JobQueryInput) {
    jobs(input: $input) {
      starts_date
    }
  }
`;

export const getJobQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceJobFragment',
      fragment: gql`
        fragment MarketplaceJobFragment on Job {
          assignment {
            is_travel
          }
          bonus_one_time_cents_for_account_id
          bonus_one_time_payout_cents
          expense_adjustments {
            amount_cents
            payout {
              amount_cents
              id
              status
              w2_submit_by_time
            }
          }
          job_billing_sums {
            payout_callback_amount_cents
            payout_callback_hours
            payout_double_amount_cents
            payout_double_hours
            payout_overtime_amount_cents
            payout_overtime_hours
            payout_regular_amount_cents
            payout_regular_hours
            payout_total_amount_cents
            total_breaks_minutes
            total_hours
            total_on_call_hours
          }
          job_billings {
            id
            payout_total_amount_cents
            payout {
              amount_cents
              id
              status
              w2_submit_by_time
            }
          }
          payout_base_hourly_rate_cents_for_account_id
          payout_base_hourly_rate_cents
          payout_double_overtime_multiplier
          payout_on_call_hourly_rate_cents
          payout_overtime_multiplier
          payout_taxable_hourly_rate_cents
          job_profile_for_account_id {
            payout_facility_bonus_cents
          }
          status
          total_payout_bonus_cents
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query Job($id: Int!, $professional_id: Int!) {
      job(id: $id) {
        ${fragment?.name}
        new_to_facility
        approved_clock_in
        approved_clock_out
        assignment {
          id
          is_w2
          track_unpaid_breaks
        }
        booked_at
        breaks {
          duration_minutes
          id
          waived
        }
        can_book_job
        can_book_job
        cancellation_fee_charge_hours
        cancellation_fee_window
        clinical_contact{
          first_name
          last_name
          phone_number
        }
        condition_ids
        covid_care
        current_ends_time
        current_starts_time
        custom_onboarding_docs {
          category
          file
          id
          name
        }
        detail
        disputes {
          id
          status
        }
        did_user_modify_time
        ends_time
        estimated_unpaid_break_time_minutes
        expense_adjustments {
          created_at
          id
          taxable_type
        }
        facility_number
        guaranteed_minimum_hours
        id
        is_w2
        job_billing_sums {
          total_breaks_minutes 
          total_hours         
        }        
        job_billings {
          id
        }
        location {
          address_city
          address_first
          address_second
          address_zip
          approval_free_late_clock_out_grace_period_minutes
          clock_in_early_window
          company_id
          coordinates {
            latitude
            longitude
          }
          covid_requirement
          drug_screening_required
          enable_geo_fence
          full_address
          geo_fence_radius
          id
          ignore_signature_when_creating_meal_break
          location_type {
            label
          }
          market {
            display_name
          }
          market_id
          maximum_late_clock_out_grace_period_minutes
          name
          parking_cost_cents
          parking_free
          parking_instructions
          parking_reimbursed
          parking_reimbursement_cents
          parking_validated
          professional_can_adjust_times
          require_facility_timecard_system_photo_on_clock_in
          require_facility_timecard_system_photo_on_clock_out
          require_upload_on_clock_out
          start_clock_in_early_window
          state {
            abbreviation
            covid_requirement
          }
          timezone_lookup
        }
        location_id
        patient_population
        positions {
          display_name
          id
          name
        }
        posting_field_contents
        pro_confirmed_at
        professional_submitted_time
        requested_by_facility(professional_id: $professional_id)
        requires_sca_onboarding_condition
        scrubs_provided
        shift_type
        specialties {
          id
          label
        }
        starts_date
        starts_time
        status
        surgery_types {
          display_name
          id
        }
        track_unpaid_breaks
        updated_in_cancellation_window
      }
    }
  `;
};

export const getAvailableJobQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceAvailableJobFragment',
      fragment: gql`
        fragment MarketplaceAvailableJobFragment on Job {
          bonus_one_time_cents_for_account_id
          bonus_one_time_payout_cents
          expense_adjustments {
            amount_cents
          }
          job_billing_sums {
            payout_total_amount_cents
            total_hours
          }
          payout_base_hourly_rate_cents_for_account_id
          job_profile_for_account_id {
            payout_facility_bonus_cents
          }
          total_payout_bonus_cents
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query AvailableJob($id: Int!, $professional_id: Int!) {
      availableJob(id: $id) {
        ${fragment?.name}
        approved_clock_in
        approved_clock_out
        assignment {
          id
          is_w2
          track_unpaid_breaks
        }
        booked_at
        breaks {
          duration_minutes
          id
          waived
        }
        can_book_job
        cancellation_fee_charge_hours
        cancellation_fee_window
        condition_ids
        covid_care
        current_ends_time
        current_starts_time
        custom_onboarding_docs {
          category
          file
          id
          name
        }
        detail
        did_user_modify_time
        estimated_unpaid_break_time_minutes
        expense_adjustments {
          created_at
          taxable_type
        }
        facility_number
        guaranteed_minimum_hours
        id
        is_w2
        location {
          address_city
          address_first
          address_second
          address_zip
          approval_free_late_clock_out_grace_period_minutes
          clock_in_early_window
          company_id
          coordinates {
            latitude
            longitude
          }
          covid_requirement
          drug_screening_required
          enable_geo_fence
          geo_fence_radius
          id
          ignore_signature_when_creating_meal_break
          location_type {
            label
          }
          market {
            display_name
          }
          market_id
          maximum_late_clock_out_grace_period_minutes
          name
          parking_cost_cents
          parking_free
          parking_instructions
          parking_reimbursed
          parking_reimbursement_cents
          parking_validated
          professional_can_adjust_times
          require_facility_timecard_system_photo_on_clock_in
          require_facility_timecard_system_photo_on_clock_out
          require_upload_on_clock_out
          start_clock_in_early_window
          state {
            abbreviation
            covid_requirement
          }
          timezone_lookup
        }
        location_id
        patient_population
        positions {
          display_name
          id
        }
        posting_field_contents
        professional_submitted_time
        requested_by_facility(professional_id: $professional_id)
        requires_sca_onboarding_condition
        scrubs_provided
        specialties {
          id
          label
        }
        starts_date
        status
        surgery_types {
          display_name
          id
        }
        track_unpaid_breaks
        updated_in_cancellation_window
      }
    }
  `;
};

export const getJobsQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceJobsFragment',
      fragment: gql`
        fragment MarketplaceJobsFragment on Job {
          assignment {
            is_travel
          }
          bonus_one_time_payout_cents
          estimated_unpaid_break_time_minutes
          expense_adjustments {
            amount_cents
            payout_id
          }
          job_billing_sums {
            payout_total_amount_cents
            total_hours
          }
          job_profile_for_account_id {
            payout_facility_bonus_cents
          }
          total_payout_bonus_cents
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query Jobs($input: JobQueryInput) {
      jobs(input: $input) {
        ${fragment?.name}
        approved_clock_in
        approved_clock_out
        assignment {
          id
          is_w2
          track_unpaid_breaks
        }
        assignment_id
        breaks {
          duration_minutes
          id
          waived
        }
        cancellation_fee_charge_hours
        cancellation_fee_window
        covid_care
        current_ends_time
        current_starts_time
        detail
        did_user_modify_time
        ends_date
        ends_time
        expense_adjustments {
          created_at
          id
          job_id
          taxable_type
        }
        facility_number
        guaranteed_minimum_hours
        id
        is_w2
        location {
          address_city
          address_first
          address_second
          address_zip
          approval_free_late_clock_out_grace_period_minutes
          clock_in_early_window
          company_id
          coordinates {
            latitude
            longitude
          }
          covid_requirement
          enable_geo_fence
          full_address
          geo_fence_radius
          id
          ignore_signature_when_creating_meal_break
          location_type {
            label
          }
          maximum_late_clock_out_grace_period_minutes
          name
          professional_can_adjust_times
          require_facility_timecard_system_photo_on_clock_in
          require_facility_timecard_system_photo_on_clock_out
          require_upload_on_clock_out
          start_clock_in_early_window
          state {
            abbreviation
            covid_requirement
          }
          timezone_lookup
        }
        location_id
        patient_population
        positions {
          display_name
          id
        }
        posting_field_contents
        pro_confirmed_at
        professional_submitted_time
        scrubs_provided
        shift_type
        specialties {
          id
          label
        }
        starts_date
        starts_time
        status
        surgery_types {
          display_name
          id
        }
        track_unpaid_breaks
      }
      _jobsMeta(input: $input) {
        count
      }
    }
  `;
};

export const GET_REQUESTED_JOBS = gql`
  query RequestedJobs($input: JobQueryInput) {
    availableJobs(input: $input) {
      id
      location {
        id
        name
        unit
        address_first
        address_second
        address_city
        address_zip
        state {
          abbreviation
        }
      }
    }
  }
`;

export const getJobsWithoutPayoutQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceGetJobsWithoutPayoutFragment',
      fragment: gql`
        fragment MarketplaceGetJobsWithoutPayoutFragment on Job {
          expense_adjustments {
            amount_cents
            payout_id
          }
          job_billings {
            payout_total_amount_cents
          }
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query JobsWithoutPayout($input: JobQueryInput) {
      jobsWithoutPayout(input: $input) {
        ${fragment?.name}
        assignment_id
        current_ends_time
        current_starts_time
        ends_date
        expense_adjustments {
          amount_cents
          created_at
          id
          job_id
          taxable_type
          payout {
            status
          }
        }
        id
        is_w2
        job_billings {
          id
          payout_total_amount_cents
          total_hours
          payout {
            id
            status
          }
          make_payable_at
        }
        location {
          id
          timezone_lookup
        }
        shift_type
        starts_date
        status
      }
    }
  `;
};

export const getPayoutJobsQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceGetPayoutJobsFragment',
      fragment: gql`
        fragment MarketplaceGetPayoutJobsFragment on Job {
          expense_adjustments {
            amount_cents
          }
          job_billing_sums: job_billing_sums_for_payout(payout_id: $payout_id) {
            payout_total_amount_cents
          }
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query PayoutJobs($input: JobQueryInput, $payout_id: Int) {
      jobs(input: $input) {
        ${fragment?.name}
        current_ends_time
        current_starts_time
        expense_adjustments {
          created_at
          taxable_type
        }
        id
        is_w2
        job_billing_sums: job_billing_sums_for_payout(payout_id: $payout_id) {
          total_hours
        }
        location {
          id
          name
          timezone_lookup
          unit
        }
        shift_type
        starts_date
        status
      }
    }
  `;
};

export const CHECK_USER_COORDINATES = gql`
  query UserCoordinates($id: Int!, $latitude: Float!, $longitude: Float!) {
    job(id: $id) {
      id
      location {
        id
        in_geo_fence_range(lon: $longitude, lat: $latitude)
        timezone_lookup
      }
    }
  }
`;
