import React, { Fragment, useEffect } from 'react';
import moment from 'moment';
import { capitalize } from 'core/utils/text';
import { InfoBadge, Text, Label, Heading } from '@medely/ui-kit';
import { Box, Card, Divider } from '@medely/web-components';
import { ChevronRight } from '@medely/web-components/icons';
import ConditionFormFields from 'components/credentials/ConditionFormFields';
import { useMissingConditionForm } from 'components/credentials/conditionDialogs/MissingConditionForm';
import useNearFutureExpiringConditions from 'hooks/useNearFutureExpiringConditions';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import type { MissingConditionOption } from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import type { IQualification, ICondition } from '@medely/web-components/types';
import { useTrackQualification } from 'hooks/useTrackQualification';

interface IMissingConditionFormOptionProps {
  label?: string;
  option: MissingConditionOption;
  onClick: (qualification: IQualification) => void;
}

interface IMissingSingleConditionQualificationStatus {
  option: MissingConditionOption;
  qualification: IQualification;
}

const MissingSingleConditionQualificationStatus = ({
  option,
  qualification,
}: IMissingSingleConditionQualificationStatus) => {
  const status = option.qualificationToProfessionalQualification[qualification.id]?.status;
  const { expiringConditions } = useNearFutureExpiringConditions();
  const { allProfessionalQualifications } = useProfessionalQualifications();
  const isExpiringSoon = expiringConditions.some(
    (expiringCondition) => expiringCondition.slug === qualification.slug,
  );

  if (isExpiringSoon) {
    const conditionQualificationsIds = option.qualifications.map(
      (qualification) => qualification.id,
    );
    const professionalQualifications = allProfessionalQualifications.filter((qualification) =>
      conditionQualificationsIds.includes(qualification.qualification.id),
    );
    const date = professionalQualifications[0]?.professional_qualification_attributes?.find(
      (attribute) => attribute.qualification_attribute?.field_type === 'expiration_date',
    )?.value;
    const expirationDate = date ? moment(date).format('M/D/YY') : 'soon';
    return <InfoBadge label={`Expires ${expirationDate}`} color="warning" />;
  }

  if (status === 'approved') {
    return <InfoBadge color="success" label="Approved" />;
  }
  if (status === 'rejected') {
    return <InfoBadge color="error" label="Rejected" />;
  }
  if (status === 'expired') {
    return <InfoBadge color="error" label="Expired" />;
  }
  if (option.willBeSatisfied || status === 'review') {
    return <InfoBadge color="info" label="Under Review" />;
  }
  return null;
};

const QualificationsForOption = ({ label, option, onClick }: IMissingConditionFormOptionProps) => {
  const singleMissing = option.qualifications.length === 1;
  const title = option.qualifications[0]?.long_name || option.description;

  const handleClick = (qualification?: IQualification) => {
    onClick(qualification || option.qualifications[0]);
  };

  return (
    <Card>
      <Box p={2} pb={1}>
        {label && (
          <Box mb={1}>
            <Label size="xs" color="state.teal.secondary">
              {label}
            </Label>
          </Box>
        )}
        <Box>
          {option.qualifications.map((qualification, index) => (
            <Fragment key={String(qualification.id)}>
              {index > 0 && <Divider />}
              <Box
                pb={1.5}
                pt={index > 0 ? 1.5 : 1}
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleClick(qualification)}
              >
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={0.5}
                >
                  <Label size="m">
                    {capitalize(singleMissing ? title : qualification.long_name)}
                  </Label>
                  {qualification.description && (
                    <Text size="m" color="text.secondary">
                      {qualification.description}
                    </Text>
                  )}
                </Box>
                <MissingSingleConditionQualificationStatus
                  option={option}
                  qualification={qualification}
                />
                <ChevronRight color="disabled" />
              </Box>
            </Fragment>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

const UploadMissingCondition = ({
  selectedOption,
  selectedQualificationIndex,
}: {
  selectedOption: MissingConditionOption;
  selectedQualificationIndex: number;
}) => {
  const { trackQualificationStartEditing, trackQualificationFinishEditing } =
    useTrackQualification();
  const qualification = selectedOption.qualifications[selectedQualificationIndex];

  useEffect(() => {
    if (qualification) {
      trackQualificationStartEditing({ qualificationId: qualification.id });
    }

    return () => {
      if (qualification) {
        trackQualificationFinishEditing({ qualificationId: qualification.id });
      }
    };
  }, [qualification.id]);

  return (
    <ConditionFormFields
      name={`condition.qualifications[${selectedQualificationIndex}]`}
      condition={{ qualifications: [qualification] }}
      withQualificationName
    />
  );
};

interface IMissingConditionFormOptionsPickerProps {
  condition: ICondition;
  options: MissingConditionOption[];
  selectOption?: (option?: MissingConditionOption, qualification?: IQualification) => void;
}

const MissingConditionFormOptionsPicker = ({
  condition,
  options,
  selectOption,
}: IMissingConditionFormOptionsPickerProps) => {
  return (
    <Box data-testid="missing-condition-options-picker">
      <Heading size="m">{condition.label}</Heading>
      <Box mt={0.5}>
        <Text size="m">Complete one of the following options.</Text>
      </Box>
      {options.map((option, index) => (
        <Box key={index} mt={3}>
          <QualificationsForOption
            option={option}
            onClick={(qualification) => selectOption(option, qualification)}
          />
        </Box>
      ))}
    </Box>
  );
};

const MissingConditionFormOptions = () => {
  const { condition, selectedOption, selectOption, options, selectedQualificationIndex } =
    useMissingConditionForm();

  if (!options.length) {
    return null;
  }

  return (
    <>
      {!selectedOption ? (
        <MissingConditionFormOptionsPicker
          condition={condition}
          options={options}
          selectOption={selectOption}
        />
      ) : (
        <UploadMissingCondition
          selectedOption={selectedOption}
          selectedQualificationIndex={selectedQualificationIndex}
        />
      )}
    </>
  );
};

export default MissingConditionFormOptions;
