import { IPosition } from '@medely/types';
import { SelectedPosition } from './types';
import { OptionType } from '@medely/ui-kit';
import uniqBy from 'lodash/uniqBy';

export const getRecommendedPositions = (
  allPositions: IPosition[],
  selectedPositions: SelectedPosition[],
): OptionType[] => {
  let recommendedPositions: OptionType[] = [];
  selectedPositions.forEach((position) => {
    const selectedPosition = allPositions.find((pos) => pos.id === position.id);
    if (!selectedPosition) {
      return;
    }
    const filtered = selectedPosition.recommended_positions.filter(
      (recommendedPosition) =>
        !selectedPositions.some(
          (selectedPosition) => selectedPosition.id === recommendedPosition.id,
        ),
    );
    const mapped = filtered.map((recommendedPosition) => ({
      value: recommendedPosition.id,
      label: recommendedPosition.display_name,
      tagProps:
        recommendedPosition.incrementality === 'prioritize'
          ? { color: 'success', label: 'High demand' }
          : null,
    }));

    recommendedPositions = recommendedPositions.concat(mapped);
  });
  return uniqBy(recommendedPositions, (p) => p.value).sort((a, b) =>
    a.label.localeCompare(b.label),
  );
};
