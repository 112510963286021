import { useFirebaseContext, useConfigurationContext } from '@medely/ui-kit';
import { IAccount } from '@medely/types';
import { GET_CURRENT_USER } from '@medely/pro-multi-platform/web';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { isAgencyAccount, isSaasAccount } from 'utils/account';
import useGraphQLRequest from './useGraphQLRequest';

// TODO (INT-1219) - migrate everything to use the useCurrentUser hook from multi-platform and delete this file
const useCurrentUser = (): {
  isLoading: boolean;
  currentUser: Omit<IAccount, 'agency'> | undefined;
  isArchived: boolean;
  isDeactivated: boolean;
  isRejected: boolean;
  isApplicant: boolean;
  isSaas: boolean;
  isAgency: boolean;
  isW2Eligible: boolean;
  isW2Enabled: boolean;
  refetch: () => Promise<QueryObserverResult<IAccount, unknown>>;
} => {
  const { googleUser, logout } = useFirebaseContext();
  const { adminUrl, facilityUrl } = useConfigurationContext();
  const { request } = useGraphQLRequest();
  const enabled = !!googleUser;

  const REDIRECT_URLS = {
    administrator: adminUrl,
    facility: facilityUrl,
  };

  const getCurrentUser = async () => {
    const { me } = await request(GET_CURRENT_USER);
    return me;
  };

  const { isLoading, isInitialLoading, data, refetch } = useQuery(
    ['currentUser', googleUser?.email],
    getCurrentUser,
    {
      enabled,
      onSuccess: (account: IAccount) => {
        if (account.account_type !== 'professional') {
          logout(REDIRECT_URLS[account.account_type]);
        }
      },
    },
  );

  const isSaas = data ? isSaasAccount(data) : false;
  const isAgency = data ? isAgencyAccount(data) : false;

  return {
    isLoading: enabled ? isLoading : isInitialLoading,
    currentUser: data,
    isArchived: data?.professional?.application_status === 'archived',
    isDeactivated: data?.professional?.status === 'deactivated',
    isRejected: data?.professional?.application_status === 'rejected',
    isApplicant: data?.professional?.application_status !== 'approved',
    isSaas,
    isAgency,
    isW2Eligible: data?.professional?.w2_eligible ?? false,
    isW2Enabled: data?.professional?.w2_enabled ?? false,
    refetch,
  };
};

export default useCurrentUser;
