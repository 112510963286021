import { gql } from 'graphql-request';

export const GET_QUALIFICATIONS = gql`
  query Qualifications($input: QualificationQueryInput) {
    qualifications(input: $input) {
      id
      long_name
      admin_view_only
    }
  }
`;

export const GET_QUALIFICATION = gql`
  query Qualification($id: Int!) {
    qualification(id: $id) {
      id
      instructions
      long_name
      qualification_type
      qualification_attributes {
        active
        data
        field_type
        id
        input_source
        name
        required
        slug
      }
      default_verification_title
      qualification_automations {
        id
        description
        name
        loading_text
        is_active
        qualification_automation_attributes {
          id
          qualification_attribute {
            id
            field_type
            slug
            name
            data
            input_source
            active
          }
          required
        }
      }
    }
  }
`;
